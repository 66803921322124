import React, { Component } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import axios from '../axios'

class RemoteSupport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedData: [],
      cloudid: '',
    }
  }

  getRemoteSupport() {
    axios
      .get('/get/cloudid', {})
      .then(res => {
        const data = res.data
        this.setState({
          fetchedData: data,
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getRemoteSupport()
    this.interval = setInterval(() => this.getRemoteSupport(), 10000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateCloudId = () => {
    axios
      .post('/post/maintenance_status_reset', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          cloudid: 'ABCDEFG',
        },
      })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.error(e)
      })
    clearInterval(this.interval)
    this.setState({
      fetchedData: [],
    })
  }

  render() {
    const { fetchedData } = this.state
    return (
      <>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.remotemaintenance.title' />
        </div>
        <div className='interfaceRow'>
          <div className='quart'>
            <label htmlFor='cloudid'>
              <FormattedMessage id='pages.remotemaintenance.cloudid' />:
            </label>
          </div>
          <div
            className='quart'
            style={{
              paddingLeft: '1rem',
            }}
          >
            {fetchedData.cloudid}
          </div>
        </div>
        <div className='interfaceRow'>
          <div className='quart'>
            <FormattedMessage id='pages.remotemaintenance.clientid' />:
          </div>
          <div
            className='quart'
            style={{
              paddingLeft: '1rem',
            }}
          >
            {fetchedData.clientid}
          </div>
        </div>
      </>
    )
  }
}

export default RemoteSupport
